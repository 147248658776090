<template>
  <v-card
    outlined
    class="d-flex align-center"
    height="40px"
    style="overflow: hidden"
    width="100%"
  >
    <v-btn
      @click="subMonth"
      color="white"
      tile
      depressed
      height="40px"
      class="px-0"
      min-width="8px"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-divider vertical class="ma-0"></v-divider>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="true"
      transition="slide-x-reverse-transition"
      slide-y-transition
      offset-y
      max-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text style="flex-grow: 1;">
          {{ showDataMes }}
          <v-icon right>mdi-calendar</v-icon>
        </v-btn>
      </template>
      <v-date-picker
        v-model="dataMes"
        type="month"
        no-title
        scrollable
        full-width
        @change="updateDate"
      >
      </v-date-picker>
    </v-menu>
    <v-divider vertical class="ma-0"></v-divider>
    <v-btn
      @click="addMonth"
      color="white"
      tile
      depressed
      height="40px"
      class="px-0"
      min-width="8px"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import {
  format,
  parseISO,
  subMonths,
  addMonths,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import { mapState } from "vuex";

export default {
  name: "DataMensal",

  data() {
    return {
      menu: false,
      dataMes: null,
    };
  },

  computed: {
    ...mapState("Filtros", {
      dataFromStore: (state) => state.filtros.dataFrom,
      dataToStore: (state) => state.filtros.dataTo,
    }),

    dataFromQuery() {
      return this.$route.query.dataFrom ? this.$route.query.dataFrom : null;
    },
    dataToQuery() {
      return this.$route.query.dataTo ? this.$route.query.dataTo : null;
    },
    showDataMes() {
      return this.dataMes
        ? format(parseISO(this.dataMes + "-01"), "MMM/yyyy", this.formatLocale)
        : format(new Date(), "MMM/yyyy", this.formatLocale);
    },
  },

  watch: {
    dataFromQuery: {
      handler() {
        if (this.dataFromQuery) {
          this.dataMes = format(parseISO(this.dataFromQuery), "yyyy-MM");
        }
      },
      immediate: true,
    },
  },

  methods: {
    subMonth() {
      if (this.dataMes) {
        this.dataMes = format(
          subMonths(parseISO(this.dataMes + "-01"), 1),
          "yyyy-MM"
        );
      } else {
        this.dataMes = format(subMonths(new Date(), 1), "yyyy-MM");
      }
      this.updateDate(this.dataMes);
    },

    addMonth() {
      if (this.dataMes) {
        this.dataMes = format(
          addMonths(parseISO(this.dataMes + "-01"), 1),
          "yyyy-MM"
        );
      } else {
        this.dataMes = format(addMonths(new Date(), 1), "yyyy-MM");
      }
      this.updateDate(this.dataMes);
    },

    updateDate(date) {
      if (date) {
        this.$store.commit(
          "Filtros/UPDATE_DATAFROM",
          format(startOfMonth(parseISO(date + "-01")), "yyyy-MM-dd")
        );

        this.$store.commit(
          "Filtros/UPDATE_DATATO",
          format(endOfMonth(parseISO(date + "-01")), "yyyy-MM-dd")
        );
      } else {
        this.$store.commit(
          "Filtros/UPDATE_DATAFROM",
          format(startOfMonth(new Date()), "yyyy-MM-dd")
        );

        this.$store.commit(
          "Filtros/UPDATE_DATATO",
          format(endOfMonth(new Date()), "yyyy-MM-dd")
        );
      }
    },
  },
};
</script>

<style></style>
